/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useRef, useCallback, useLayoutEffect } from 'react';
import { FormHandles } from '@unform/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import pointerIcon from 'assets/svg/pointerIcon.svg';
import Icon from 'components/Tools/Icon';
import TransferList from '../../../../../../components/Tools/TransferList';
import {
  editUserRequest,
  getAllModalOptions,
  resetPasswordRequest,
} from '../../../api/users.api';
import PageMode from '../../../../../../components/Tools/PageMode';
import { IEditProfileModalProps } from '../../../types';
import { FilialList } from '../../../../../../utils/interfaces';
import { SelectItem } from '../../../../../../utils/toSelect';

const EditUserModal: React.FC<IEditProfileModalProps> = ({
  handleClose,
  isOpen,
  userData,
  clearCurrentUser,
  getUsers,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [, setSelectAllSystemsEdit] = useState(true);

  const [profiles, setProfiles] = useState([]);
  const [filialList, setFilialList] = useState<FilialList[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [supplierData, setSupplierData] = useState<SelectItem[]>([]);
  const [selectedListEdit, setSelectedListEdit] = useState<FilialList[]>([]);
  const { t } = useTranslation();
  const traductorMsg = (msg: string) => {
    return i18n.t(msg);
  };
  const editModalRef = useRef<FormHandles>(null);
  const clientId = Number(localStorage.getItem('@pdamodules::codigoCliente'));

  const { enqueueSnackbar } = useSnackbar();

  const handleCloseEditModal = useCallback(() => {
    clearCurrentUser();
    handleClose();
    getUsers();
  }, [handleClose, clearCurrentUser, getUsers]);

  const handleResetPassword = useCallback(async () => {
    setIsLoading(true);

    await resetPasswordRequest(userData.codigoUsuario)
      .then(() => {
        enqueueSnackbar(traductorMsg('screens.user.editPassword'), {
          variant: 'success',
        });
      })
      .catch(err => {
        enqueueSnackbar(`Erro: ${err.message}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [enqueueSnackbar, userData]);

  const handleEditProfile = useCallback(
    async (data: object) => {
      setIsLoading(true);

      await editUserRequest(
        clientId,
        data,
        selectedListEdit,
        userData.codigoUsuario
      )
        .then(() => {
          enqueueSnackbar(traductorMsg('screens.profile.editSucces'), {
            variant: 'success',
          });
          handleCloseEditModal();
        })
        .catch(err => {
          enqueueSnackbar(
            `${traductorMsg('screens.user.error')}: ${err.message}`,
            {
              variant: 'error',
            }
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [
      selectedListEdit,
      enqueueSnackbar,
      clientId,
      handleCloseEditModal,
      userData,
    ]
  );

  const setEditList = useCallback((list: FilialList[]) => {
    setSelectedListEdit(list);
  }, []);

  const handleInitiateModal = useCallback(async () => {
    setIsLoading(true);

    const [filiaisRes, profilesRes, supplier] = await getAllModalOptions(
      clientId
    );

    const initialProfiles: SelectItem[] = profilesRes;
    const initialSupplier: SelectItem = supplier.find((p: SelectItem) => {
      return p.value === userData?.codigoFornecedor;
    });

    setProfiles(profilesRes);
    setFilialList(filiaisRes);
    setSupplierData(supplier);

    setIsLoading(false);

    const itensFiltered: SelectItem[] = Object.values(initialProfiles).filter(
      // @ts-ignore
      itens => itens.value === userData!.codigoPerfil
    );

    const filtered = itensFiltered[0];

    editModalRef.current?.setFieldValue('login', userData!.login);
    editModalRef.current?.setFieldValue('nome', userData!.nome);
    editModalRef.current?.setFieldValue('email', userData?.email || '');
    editModalRef.current?.setFieldValue('codigoPerfil', { ...filtered });
    editModalRef.current?.setFieldValue('ativo', userData!.ativo);
    if (initialSupplier) {
      editModalRef.current?.setFieldValue('codigoFornecedor', {
        label: initialSupplier!.label,
        value: initialSupplier!.value,
      });
    }
  }, [clientId, userData]);

  useLayoutEffect(() => {
    handleInitiateModal();
  }, [handleInitiateModal]);

  return (
    <PageMode
      isModal
      open={isOpen}
      submitButtonIcon
      modalRefObject={editModalRef}
      modalLoading={isLoading}
      submitButtonTitle={String(t('screens.profileTable.edit'))}
      handleSubmit={data => handleEditProfile(data)}
      title={`${t('screens.user.editUser')}: ${userData.login}`}
      handleClose={handleCloseEditModal}
      inputs={[
        {
          name: 'login',
          label: String(t('screens.userTable.login')),
          placeholder: String(t('screens.userTable.login')),
          type: 'text',
          isRequired: true,
          xl: 6,
          lg: 4,
          xs: 12,
          md: 6,
          sm: 8,
        },
        {
          name: 'nome',
          label: String(t('screens.user.name')),
          placeholder: String(t('screens.user.name')),
          type: 'text',
          isRequired: true,
          xl: 6,
          lg: 4,
          xs: 12,
          md: 6,
          sm: 8,
        },
        {
          name: 'email',
          label: 'E-mail',
          placeholder: 'E-mail',
          type: 'text',
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 8,
        },
        {
          name: 'codigoPerfil',
          label: String(t('screens.profile.title')),
          placeholder: String(t('screens.profile.title')),
          type: 'select',
          isRequired: true,
          options: profiles,
          xl: 4,
          lg: 4,
          xs: 12,
          md: 4,
          sm: 8,
        },
        // {
        //   name: 'codigoFornecedor',
        //   label: 'Fornecedor',
        //   placeholder: 'Fornecedor',
        //   type: 'select',
        //   options: supplierData,
        //   xl: 4,
        //   lg: 4,
        //   xs: 12,
        //   md: 4,
        //   sm: 8,
        // },
        {
          name: 'ativo',
          label: String(t('screens.user.isActive')),
          type: 'switch',
          isRequired: true,
        },
      ]}
      newButton
      newButtonActions={[
        {
          onClick: () => handleResetPassword(),
          renderItem: () => (
            <>
              <Icon icon={pointerIcon} color="#0095e8" />
              <p>{t('screens.user.resetPassword')}</p>
            </>
          ),
        },
      ]}
    >
      {filialList.length ? (
        <TransferList
          title={t('screens.user.branchs')}
          height={10}
          initialList={filialList}
          path="codigoFilialERP"
          subPath="tipo"
          setSelectedList={setEditList}
          selectedList={userData.filiais}
          setStateOption={setSelectAllSystemsEdit}
        />
      ) : null}
    </PageMode>
  );
};

export default EditUserModal;
